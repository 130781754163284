import { render, staticRenderFns } from "./DraftMyPurchaseRequest.vue?vue&type=template&id=0a4b9786&scoped=true&"
import script from "./DraftMyPurchaseRequest.vue?vue&type=script&lang=js&"
export * from "./DraftMyPurchaseRequest.vue?vue&type=script&lang=js&"
import style0 from "./DraftMyPurchaseRequest.vue?vue&type=style&index=0&id=0a4b9786&prod&lang=scss&scoped=true&"
import style1 from "./DraftMyPurchaseRequest.vue?vue&type=style&index=1&id=0a4b9786&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a4b9786",
  null
  
)

export default component.exports